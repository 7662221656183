<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>
<style>
.logo {
    font-family: 'Oswald', sans-serif;
    color: #333333;
}

.logo span {
    color: #2E80ED;
}

.logo.footerlogo {
    font-size: 18px;
}

.app-brand {
    min-height: 60px;
}
.btn-primary {
    background-color:#2E80ED;
}
</style>
<script>
export default {
    name: 'app',
    metaInfo: {
        title: 'Index',
        titleTemplate: '%s - Vue Starter',
    },
    updated() {
        // Remove loading state
        setTimeout(() => document.body.classList.remove('app-loading'), 1);
    },
};
</script>
