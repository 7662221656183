<template>
    <div
        class="ui-toast component"
        :class="classList"
        @click="remove">
        {{ item.message }}
    </div>
</template>

<script>
export default {
    name: 'UiToast',
    props: {
        item: {
            type: Object
        }
    },
    methods: {
        remove () {
            this.$emit('remove', this.item.id)
        }
    },
    computed: {
        classList () {
            return {
                default: this.item.type === 'default',
                success: this.item.type === 'success',
                info: this.item.type === 'info',
                warning: this.item.type === 'warning',
                error: this.item.type === 'error'
            }
        }
    },
    mounted () {
        if (this.item.duration) {
            const timer = setTimeout(() => {
                clearTimeout(timer)
                this.remove()
            }, this.item.duration)
        }
    }
}
</script>

<style lang="scss">
.ui-toast.component {
    cursor: pointer;
    padding: 20px;
    background-color: #ffffff;
    margin: 20px 0;
    &.default {
        border-right: 5px solid #ffffff;
    }
    &.success {
        border-right: 5px solid green;
        background-color: rgba(0, 255, 0, 0.4);
    }
    &.info {
        border-right: 5px solid blue;
        background-color: rgba(0, 0, 255, 0.4);
    }
    &.warning {
        border-right: 5px solid orange;
        background-color: rgba(255, 165, 0, 0.4);
    }
    &.error {
        border-right: 5px solid #d9534f;
        background-color: #f9e5e5;
        color:#645c5c;
    }
}
</style>
