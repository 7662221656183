import { Http } from './http.init';
import $router from '../router';


export class AuthService {
    /**
     ******************************
     * @API
     ******************************
     */

    static async makeLogin({ email, password }) {
        try {
            const response = await new Http({ auth: false }).post(
                '/user/api/login',
                { email, password },
            );
            //  eslint-disable-next-line no-use-before-define
            setAuthData(response.data.data.access_token);
            return response;
        } catch (error) {
            return error;
        }
    }

    static async makeLogout() {
        try {
            const response = await new Http({ auth: true }).post(
                'logout/', {},
            );
            // eslint-disable-next-line no-use-before-define
            resetAuthData();
            $router.push({ name: 'authenticate' }).catch(() => {});
            return response;
        } catch (error) {
            return error;
        }
    }

    static getBearer() {
        return `Bearer `+ localStorage.getItem('accessToken');
    }

    static setBearer(accessToken) {
        localStorage.setItem('accessToken', accessToken)

    }
}

function setAuthData(token) {
    AuthService.setBearer(token);
}

function resetAuthData() {
    // reset userData in store
    //  $store.commit('user/SET_CURRENT_USER', {})
    // reset tokens
    AuthService.setBearer('');
}
