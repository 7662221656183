import { Http } from './http.init';

export default class UsersService {
    static get entity() {
        return 'users';
    }

    static async getCurrent() {
        try {
            const response = await new Http({ auth: true }).get('user/api/current');
            return response.data;
        } catch (error) {
            return error;
        }
    }

    static async getNegociators() {
      try {
        const response = await new Http({ auth: true }).get('api/negociator');
        return response.data;
      }
      catch(error) {
        return error;
      }
    }

    static async getNegociator(id) {
      try {
        const response = await new Http({ auth: true }).get(`api/negociator/${id}`);
        return response.data;
      }
      catch (error) {
        return error;
      }
    }

    static async deleteNegociator(id) {
      try {
        const response = await new Http({ auth: true }).delete(`api/negociator/${id}`);
        return response.data;
      }
      catch (error) {
        return error;
      }
    }

    static async modifyNegociator(id, color) {
      try {
        const response = await new Http({ auth: true}).put(
          `api/negociator/${id}`,
          {color}
          );
        return response.data;
      }
      catch (error) {
        return error;
      }
    }

    static async createNegociator({firstname, lastname, email, color}) {
      try {
        const response = await new Http({ auth: true}).post(
          'api/negociator',
          {firstname, lastname, email, color}
        )
        return response.data;
      }
      catch (e) {
        return e;
      }
    }

    static async modifyNegociatorItSelf(color, firstname, lastname) {
      try {
        const response = await new Http({ auth: true}).put(
          `api/negociator/me`,
          {color, firstname, lastname}
          );
        return response.data;
      }
      catch (error) {
        return error;
      }
    }

    static async getNegociatorItSelf() {
      try {
        const response = await new Http({ auth: true}).get(
          `api/negociator/me`,
          );
        return response.data;
      }
      catch (error) {
        return error;
      }
    }

    static async sendEmail(email) {
      try {
        const response = await new Http({ auth: true}).post(
          `user/api/send/email`,
          {email}
          );
        return response.data;
      }
      catch (error) {
        return error;
      }
    }

    static async setNegociator(token, {firstname, lastname, color, password}) {
      try {
        const response = await new Http({ auth: true}).post(
          `user/api/create/negociator/${token}`,
          {firstname, lastname, color, password}
          );
        return response.data;
      }
      catch (error) {
        return error;
      }
    }
}
