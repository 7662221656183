// Additional polyfills
import 'custom-event-polyfill';
import 'url-polyfill';

import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
import Popper from 'popper.js';

import globals from './globals';

import App from './App.vue';
import router from './router';
import store from './store';

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

// Global RTL flag
Vue.mixin({
    data: globals,
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
