export default [{
    path: '/properties/',
    component: () => import('@/pages/property/PropertyList.vue'),
}, {
    path: '/property/:id/detail/',
    component: () => import('@/pages/property/PropertyDetail.vue'),
}, {
    path: '/property/create/',
    component: () => import('@/pages/property/PropertyForm.vue'),
}, {
  path: '/property/:id/comment',
  component: () => import('@/pages/property/PropertyComment.vue'),
}];
