export default [{
    path: '/authenticate/',
    name: 'authenticate',
    component: () => import('@/pages/auth/Authentication.vue'),
}, {
    path: '/change-password/',
    component: () => import('@/pages/auth/ChangePassword.vue'),
}, {
    path: '/forgotten-password/',
    component: () => import('@/pages/auth/ForgottenPassword.vue'),
}, {
  path: '/creation/negociator/:token',
  component: () => import('@/pages/users/UsersDetail.vue'),
}];
