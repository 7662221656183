<template>
    <sidenav :orientation="orientation" :class="curClasses">
        <!-- Brand demo (see src/demo.css) -->
        <div class="app-brand ml-0 pl-0"  v-if="orientation !== 'horizontal'">
            <router-link to="/" class="app-brand-text demo sidenav-text font-weight-bold ml-2" style="font-size:18px;">
                <img class="inline-block" src="@/assets/logo-selika.png" style="width:60px;"/>
                <span class="ml-3  d-inline-block" style="line-height:60px;">Sélik’proSpection</span>
            </router-link>

        </div>
        <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>
        <!-- Links -->
        <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
            <sidenav-router-link icon="ion-md-map" to="/prospecting/" :exact="true">Prospection
            </sidenav-router-link>
            <sidenav-router-link icon="ion-md-filing" to="/properties/" :exact="true">Biens
            </sidenav-router-link>
            <sidenav-router-link icon="ion-ios-pin" to="/influencer/" :exact="true">Influenceur
            </sidenav-router-link>
            <sidenav-router-link v-if="user.custom_group.label === 'Admin'" icon="ion-md-person" to="/users/" :exact="true">Utilisateurs
            </sidenav-router-link>
            <div class="mt-5">
                <a href="/modedemploi.pdf" style=" margin-left:20px; font-size: 14px; margin-top:50px;
    font-weight: 600;
    color: #333333 !important; text-decoration:underline;" target="_blank"><i class="sidenav-icon ion-md-cloud-download"></i>Télécharger le mode d'emploi</a>
            </div>
        </div>

    </sidenav>
</template>
<script>
import {
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
} from '@/vendor/libs/sidenav';
import  $store from '../store';

export default {
    name: 'app-layout-sidenav',
    components: {
        /* eslint-disable vue/no-unused-components */
        Sidenav,
        SidenavLink,
        SidenavRouterLink,
        SidenavMenu,
        SidenavHeader,
        SidenavBlock,
        SidenavDivider
        /* eslint-enable vue/no-unused-components */
    },

    data() {
      return {
        user: $store.state.user.currentUser
      }
    },

    props: {
        orientation: {
            type: String,
            default: 'vertical'
        }
    },

    computed: {
        curClasses () {
            let bg = this.layoutSidenavBg

            if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
                bg = bg
                    .replace(' sidenav-dark', '')
                    .replace(' sidenav-light', '')
                    .replace('-darker', '')
                    .replace('-dark', '')
            }
            return 'bg-white ' + (
                this.orientation !== 'horizontal'
                    ? 'layout-sidenav'
                    : 'layout-sidenav-horizontal container-p-x flex-grow-0'
            )
        }
    },

    methods: {
        isMenuActive (url) {
            return this.$route.path.indexOf(url) === 0
        },

        isMenuOpen (url) {
            return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
        },

        toggleSidenav () {
            this.layoutHelpers.toggleCollapsed()
        }
    }
}
</script>
<style>
.sidenav-item .sidenav-link {
    font-size: 18px;
    font-weight: 600;
    color: #333333 !important;
}

.sidenav-item.active .sidenav-link, .sidenav-item:hover .sidenav-link, .sidenav-item.open .sidenav-link {
    color: #ffffff !important;
    background-color: #2E80ED !important
}

.sidenav-item.active .sidenav-link, .sidenav-link:hover {
    background-color: #2E80ED !important;
}

.sidenav-menu {
    background-color: rgba(46, 128, 237, 0.50) !important;
}

.sidenav-menu .sidenav-item .sidenav-link {
    background: none !important;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
}

.sidenav-menu .sidenav-item:hover, sidenav-menu .sidenav-item.active {

}

.sidenav-menu .sidenav-item:hover .sidenav-link, .sidenav-menu .sidenav-item.active .sidenav-link {
    font-weight: 600;
    background-color: rgba(46, 128, 237, 0.75) !important;
}

.sidenav-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.sidenav-vertical .sidenav-menu .sidenav-link {
    padding-top: .550rem;
    padding-bottom: .550rem;
}

;
</style>
