/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from 'axios';

import { AuthService } from '@/services/auth.service';
import { API_URL } from '../.env';

export class Http {
    constructor(status) {
        this.isAuth = status && status.auth ? status.auth : false;
        this.instance = axios.create({
            baseURL: API_URL,
        });
        return this.init();
    }

    init() {
        if (this.isAuth) {
            this.instance.interceptors.request.use((request) => {
                // eslint-disable-next-line no-param-reassign
                request.headers.authorization = AuthService.getBearer();
                return request;
            }, (error) => Promise.reject(error));
        }
        return this.instance;
    }
}
