export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        /**
         * breakpoint constants
         */
        widthExtraSmall: 320,
        widthSmall: 640,
        widthTablet: 1024
    },

    mutations: {
        SET_WINDOW_WIDTH(state, value) {
            state.windowWidth = value;
        },
    },

    getters: {
        isExtraSmall: (state) => state.windowWidth <= state.widthExtraSmall,
        isSmall: (state) => state.windowWidth <= state.widthSmall,
        isTablet: (state) => state.windowWidth <= state.widthTablet,
        isDesktop: (state) => state.windowWidth > state.widthTablet,
    },
};
