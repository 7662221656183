import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import globals from '@/globals';
// Layouts;;
import Layout from '@/layout/Layout2.vue';
import authRoutes from '@/router/auth';
import usersRoutes from '@/router/users';
import propertyRoutes from '@/router/property';
import mapRoutes from '@/router/map';
import influencerRoutes from '@/router/influencer';

import { isAuthenticate ,initCurrentUserStateMiddleware, isAdmin } from './middlewares';

Vue.use(Router);
Vue.use(Meta);

const ADMINROUTES = [
  {
    path: '/',
    beforeEnter: isAdmin,
    component: Layout,
    children: [
        {
          path: 'user',
          component: () => import('@/pages/users/UsersList.vue'),
        },
    ]
    .concat(usersRoutes)
},
]

const ROUTES = [
    {
        path: '/',
        beforeEnter: isAuthenticate,
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/pages/Dashboard.vue'),
            },
            {
                path: 'prospecting',
                component: () => import('@/pages/Prospecting.vue'),
            },
        ].concat(propertyRoutes).concat(mapRoutes).concat(influencerRoutes)
    },
].concat(authRoutes).concat(ADMINROUTES);

const router = new Router({
    base: '/',
    mode: 'history',
    routes: ROUTES,
});

router.beforeEach(initCurrentUserStateMiddleware);

router.afterEach(() => {
    // On small screens collapse sidenav
    // eslint-disable-next-line no-undef
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        // eslint-disable-next-line no-undef
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
    // Set loading state
    // eslint-disable-next-line no-undef
    document.body.classList.add('app-loading');

    // Add tiny timeout to finish page transition
    setTimeout(() => next(), 10);
});

export default router;
