import $store from '../store';
import { Role } from '@/globals';
//import $router from '../router';

/**
 * Current user state initialization
 * @WARN Must be always first in middleware chain
 */
export async function initCurrentUserStateMiddleware(to, from, next) {
    if  ($store.state.user.currentUser) {
        const currentUserId = $store.state.user.currentUser.id;
        if (!currentUserId) {
            try {
                await $store.dispatch('user/getCurrent');
                next();
            } catch (e) {
                next();
            }
        }
    }
    next();
}

/**
 * Check access permission to auth routes
 */
// eslint-disable-next-line consistent-return
export function isAuthenticate(to, from, next) {
    if ($store.state.user.currentUser){
        const currentUserId = $store.state.user.currentUser.id;
        if (currentUserId){
            next();
        }
        else {
            next('/authenticate/');
        }
    }
    else {
        next('/authenticate/');
    }

}

export function isAdmin(to, from, next) {
  if ($store.state.user.currentUser) {
    const role = $store.state.user.currentUser.custom_group.label;
    if (role === Role.Admin) {
      next();
    }
    else {
      $store.commit('toast/NEW', { type: 'error', message: "Seulement pour les administrateurs."});
      next('/prospecting/');
    }
  }
  else {
    next('/authenticate/');
  }
}

export function setPageTitleMiddleware(to, from, next) {
    const pageTitle = to.matched.find((item) => item.meta.title);
    if (pageTitle) window.document.title = pageTitle.meta.title;
    next();
}
